import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { ILessonType } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'

export const StyledVideoListPageWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.m};
  grid-template-columns: repeat(1, 1fr);
  margin-top: ${({ theme }) => theme.spacing.m};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    gap: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.ms};
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StyledVideoListPaginatedWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.ml};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: 88px;
  }
`

export const StyledVideoListCounterWrapper = styled.p`
  ${({ theme }) => theme.typo.h4};

  margin-bottom: ${({ theme }) => theme.spacing.s};
  text-align: center;

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-bottom: ${({ theme }) => theme.spacing.ms};
    margin-top: ${({ theme }) => theme.spacing.xxxl};
    text-align: left;
  }
`
export const StyledVideoListItemWrapper = styled.div<{ $lessonType?: ILessonType }>`
  display: block;
`
export const StyledVideoThumbnailLink = styled(NextLink)`
  display: block;
  position: relative;
`
export const StyledVideoImageWrapper = styled.div`
  aspect-ratio: 3/2;
  border-radius: ${({ theme }) => theme.spacing.ms};
  display: block;
  overflow: hidden;
  position: relative;

  @supports not (aspect-ratio: 1) {
    padding-top: 66.67%;
  }
`
export const StyledVideoImageCover = styled.div`
  background: linear-gradient(${({ theme }) => theme.color.black50}, transparent);
  border-top-left-radius: ${({ theme }) => theme.spacing.ms};
  border-top-right-radius: ${({ theme }) => theme.spacing.ms};
  display: flex;
  inset: 0;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.ms}`};
  position: absolute;

  &:hover {
    background: linear-gradient(
      ${({ theme }) => theme.color.terracotaDark50},
      transparent
    );
  }
`
export const StyledVideoBottomWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xs};

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    margin-top: ${({ theme }) => theme.spacing.s};
  }
`
export const StyledVideoTitle = styled(NextLink)`
  display: block;
  ${({ theme }) => theme.typo.h5}
  ${({ theme }) => theme.typo.bold}
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`
export const StyledVideoInfosWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.grey};
  display: flex;
  justify-content: flex-start;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.s};
  }
`

export const StyledVideoProgress = styled.progress.attrs(() => ({
  max: 100,
}))`
  appearance: none;
  border: none;
  border-radius: 5px;
  bottom: 15px;
  height: 3px;
  left: ${({ theme }) => theme.spacing.s};
  position: absolute;
  width: 92%;

  &::-moz-progress-bar {
    background-color: ${({ theme }) => theme.color.terracota};
    border-radius: 5px;
  }

  &::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.color.grey};
    border-radius: 5px;
  }

  &::-webkit-progress-value {
    background-color: ${({ theme }) => theme.color.terracota};
    border-radius: 5px;
  }
`
