import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { IVideo } from '@/types/main'

interface IProps {
  flags: IVideo['flags']
}

export const VideoBadge: React.FC<IProps> = ({ flags }) => {
  if (!flags?.length) {
    return null
  }

  if (flags.includes('advantage')) {
    return <VideoAdvantageBadge />
  }
}

const VideoAdvantageBadge: React.FC = () => (
  <StyledBadge $color="pinkDark">
    <FormattedMessage
      defaultMessage="Accès illimité"
      description="VideoAdvantageBadge: label"
    />
  </StyledBadge>
)

const StyledBadge = styled.span<{ $color: string }>`
  ${({ theme }) => theme.typo.bold};

  background-color: ${({ theme, $color }) => theme.color[$color]};
  border: 1px solid ${({ theme, $color }) => theme.color[$color]};
  border-radius: 8px;
  bottom: 18px;
  color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
  position: absolute;
  right: ${({ theme }) => theme.spacing.s};
  text-align: center;
  text-transform: uppercase;
`
