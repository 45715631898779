import { IVideoFiltersState } from '@/reducers/videoFilters'
import { IVideo, IVideoDecorated, IVideoGroup, IVideoGroupDecorated } from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

import { IGQLPageVariables, IPageGQLResults } from './api-graphql'
import { MINIMAL_PROPERTY_FIELDS } from './api-graphql-property'
import { MINIMAL_TEACHER_FIELDS } from './api-graphql-teacher'

export type IVideoGQLResults = IPageGQLResults<IVideo[]>
export type IVideoDecoratedGQLResults = IPageGQLResults<IVideoDecorated[]>
export type IVideoGroupGQLResults = IPageGQLResults<IVideoGroup[]>
export type IVideoGroupDecoratedGQLResults = IPageGQLResults<IVideoGroupDecorated[]>
export type IVideoAndVideoGroupGQLResults = {
  video: IVideoGQLResults
  videoGroup: IVideoGroupGQLResults
}
export type IVideoAndVideoGroupDecoratedGQLResults = {
  video: IVideoDecoratedGQLResults
  videoGroup: IVideoGroupDecoratedGQLResults
}

export interface IVideoResultsVariables extends IGQLVideoVariables {
  gqlQuery?: 'Recommended'
  freePlay?: boolean
  private?: boolean
}
export const getVideoResults = async (
  variables: IVideoResultsVariables = {}
): Promise<IVideoGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query Video${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $levelIds: [Int]
      $intensityIds: [Int]
      $durationIds: [Int]
      $disciplineIds: [Int]
      $objectiveIds: [Int]
      $materialIds: [Int]
      $bodyPartIds: [Int]
      $teachedByIds: [Int]
      $groupUuids: [String]
      $multiSearch: String
      $freePlay: Boolean
      $private: Boolean
      $order_: [VideoFilter_order]
    ) {
      videos: collectionPageQuery${gqlQuery}Videos (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        level_id_list: $levelIds
        intensity_id_list: $intensityIds
        duration_id_list: $durationIds
        extended_discipline_id_list: $disciplineIds
        objective_id_list: $objectiveIds
        materials_id_list: $materialIds
        bodyParts_id_list: $bodyPartIds
        teachedBy_id_list: $teachedByIds
        groups_uuid_list: $groupUuids
        multiSearch: $multiSearch
        freePlay: $freePlay
        private: $private
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_VIDEO_FIELDS}
          teachedBy {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          duration {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videos: IVideoGQLResults
  }>(query, gqlVariables)

  return graphqlResult.data.videos
}

export interface IVideoResultVariables {
  videoUuid?: string
  videoSlug?: string
}
export const getVideoResult = async (
  variables: IVideoResultVariables
): Promise<IVideo> => {
  const query = `
    query VideoResult (
      $videoUuid: String
      $videoSlug: String
    ) {
      videos: collectionPageQueryVideos (
        uuid: $videoUuid
        slug: $videoSlug
      ) {
        collection {
          ${MINIMAL_VIDEO_FIELDS}
          authUserAccess
          description
          videoUrl
          teaserVideoUrl
          publishAt
          availableAt
          teachedBy {
            ${MINIMAL_TEACHER_FIELDS}
            disciplinesOnly
            bio
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          intensity {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          duration {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
          materials {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          bodyParts {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          groups {
            collection {
              ${MINIMAL_VIDEO_GROUP_FIELDS}
              videos (itemsPerPage: 4) {
                paginationInfo {
                  totalCount
                }
                collection {
                  ${MINIMAL_VIDEO_FIELDS}
                  teachedBy {
                    ${MINIMAL_TEACHER_FIELDS}
                  }
                  level {
                    ${MINIMAL_PROPERTY_FIELDS}
                  }
                  duration {
                    ${MINIMAL_PROPERTY_FIELDS}
                  }
                  discipline {
                    ${MINIMAL_PROPERTY_FIELDS}
                    practice: parent {
                      ${MINIMAL_PROPERTY_FIELDS}
                      pillar: parent {
                        ${MINIMAL_PROPERTY_FIELDS}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videos: IVideoGQLResults
  }>(query, variables)

  return graphqlResult.data.videos.collection[0] ?? null
}

export interface IVideoGroupResultsVariables extends IGQLVideoVariables {
  gqlQuery?: 'Conditioned'
  freePlay?: boolean
  advantage?: boolean
  type?: IVideoGroup['type']
}
export const getVideoGroupResults = async (
  variables: IVideoGroupResultsVariables = {}
): Promise<IVideoGroupGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query VideoGroup${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $disciplineIds: [Int]
      $teachedByIds: [Int]
      $multiSearch: String
      $freePlay: Boolean = false
      $advantage: Boolean
      $type: String = "theme"
      $order_: [VideoGroupFilter_order]
    ) {
      videoGroups: collectionPageQuery${gqlQuery}VideoGroups (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        disciplines_id_list: $disciplineIds
        teachedBy_id_list: $teachedByIds
        multiSearch: $multiSearch
        freePlay: $freePlay
        advantage: $advantage
        type: $type
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_VIDEO_GROUP_FIELDS}
          videos (itemsPerPage: 1) {
            paginationInfo {
              totalCount
            }
            collection {
              thumbnailPath
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videoGroups: IVideoGroupGQLResults
  }>(query, gqlVariables)

  return graphqlResult.data.videoGroups
}

export interface IVideoGroupResultVariables {
  videoGroupUuid?: string
  videoGroupSlug?: string
}
export const getVideoGroupResult = async (
  variables: IVideoGroupResultVariables
): Promise<IVideoGroup> => {
  const query = `
    query VideoGroupResult (
      $videoGroupUuid: String
      $videoGroupSlug: String
    ) {
      videoGroups: collectionPageQueryEnrichedVideoGroups (
        uuid: $videoGroupUuid
        slug: $videoGroupSlug
      ) {
        collection {
          ${MINIMAL_VIDEO_GROUP_FIELDS}
          type
          description
          publishAt
          computedProps
          favorites
          disciplines {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videoGroups: IVideoGroupGQLResults
  }>(query, variables)

  return graphqlResult.data.videoGroups.collection[0] ?? null
}

export interface IVideoAndVideoGroupResultVariables {
  uuids: string[]
}
export const getVideoAndVideoGroupResults = async (
  variables: IVideoAndVideoGroupResultVariables
): Promise<IVideoAndVideoGroupGQLResults> => {
  const query = `
    query VideoAndVideoGroupResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $uuids: [String]
    ) {
      video: collectionPageQueryVideos (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        uuid_list: $uuids
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_VIDEO_FIELDS}
          teachedBy {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          duration {
            ${MINIMAL_PROPERTY_FIELDS}
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
        }
      }

      videoGroup: collectionPageQueryVideoGroups (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        uuid_list: $uuids
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_VIDEO_GROUP_FIELDS}
          videos (itemsPerPage: 1) {
            paginationInfo {
              totalCount
            }
            collection {
              thumbnailPath
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    video: IVideoGQLResults
    videoGroup: IVideoGroupGQLResults
  }>(query, variables)

  return graphqlResult.data
}

export const getVideoSitemapResults = async (
  variables: IGQLPageVariables = {}
): Promise<IVideoGQLResults> => {
  const query = `
    query VideoSitemapResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 50
      $private: Boolean = false
    ) {
      videos: collectionPageQueryVideos (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        private: $private
      ) {
        collection {
          ${MINIMAL_VIDEO_FIELDS}
          publishAt
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videos: IVideoGQLResults
  }>(query, variables)

  return graphqlResult.data.videos
}

export const getVideoGroupSitemapResults = async (
  variables: IGQLPageVariables = {}
): Promise<IVideoGroupGQLResults> => {
  const query = `
    query VideoGroupSitemapResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 50
      $type: String = "theme"
    ) {
      videoGroups: collectionPageQueryVideoGroups (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        type: $type
      ) {
        collection {
          ${MINIMAL_VIDEO_GROUP_FIELDS}
          publishAt
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    videoGroups: IVideoGroupGQLResults
  }>(query, variables)

  return graphqlResult.data.videoGroups
}

// Mapped to reducer
export interface IGQLVideoVariables extends IGQLPageVariables {
  order_?: unknown[]
  levelIds?: number[]
  intensityIds?: number[]
  durationIds?: number[]
  disciplineIds?: number[]
  objectiveIds?: number[]
  materialIds?: number[]
  bodyPartIds?: number[]
  teachedByIds?: number[]
  groupUuids?: string[]
  multiSearch?: string
}

export const buildGqlVariablesFromVideoFilters = (
  videoFiltersState: IVideoFiltersState
): IGQLVideoVariables => {
  const {
    pageIndex,
    sortKey,
    levelIds,
    intensityIds,
    durationIds,
    disciplineIds,
    objectiveIds,
    materialIds,
    bodyPartIds,
    teachedByIds,
    groupUuids,
    multiSearch,
  } = videoFiltersState

  const [sortParam, sortDir] = sortKey.split('-')

  return {
    pageIndex,
    ...(sortParam && sortDir && { order_: [{ [sortParam]: sortDir }] }),
    ...(levelIds.length && { levelIds }),
    ...(intensityIds.length && { intensityIds }),
    ...(durationIds.length && { durationIds }),
    ...(disciplineIds.length && { disciplineIds }),
    ...(objectiveIds.length && { objectiveIds }),
    ...(materialIds.length && { materialIds }),
    ...(bodyPartIds.length && { bodyPartIds }),
    ...(teachedByIds.length && { teachedByIds }),
    ...(groupUuids.length && { groupUuids }),
    ...(multiSearch && { multiSearch }),
  }
}

export const MINIMAL_VIDEO_FIELDS = `
  id
  ulid
  thumbnailPath
  title
  slug
  flags
  authUserFavorite
  authUserSessionLatest
`
export const MINIMAL_VIDEO_GROUP_FIELDS = `
  id
  ulid
  thumbnailPath
  title
  slug
  flags
  authUserFavorite
`
