import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { IPropertyValueDiscipline, IVideoGroup } from '@/types/main'

interface IVideoCoverProps {
  discipline: IPropertyValueDiscipline
}

export const VideoCoverText: React.FC<IVideoCoverProps> = ({ discipline }) => {
  if (!discipline) {
    return null
  }

  return (
    <StyledVideoCoverText>
      {discipline.practice.pillar.title} {discipline.practice.title}
    </StyledVideoCoverText>
  )
}

interface IVideoGroupCoverProps {
  videoGroup: IVideoGroup
}

export const VideoGroupCoverText: React.FC<IVideoGroupCoverProps> = ({ videoGroup }) => (
  <StyledVideoCoverText>
    <FormattedMessage
      defaultMessage="{totalVideo, plural, =0 {Aucune vidéo} one {# vidéo} other {# vidéos}}"
      description="VideoGroupCoverText: video number"
      values={{
        totalVideo: videoGroup.videos.paginationInfo.totalCount,
      }}
    />
  </StyledVideoCoverText>
)

export const StyledVideoCoverText = styled.p`
  ${({ theme }) => theme.typo.overline}

  color: ${({ theme }) => theme.color.white};
  text-align: left;

  &::before {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: ${({ theme }) => theme.spacing.xs};
    margin-right: ${({ theme }) => theme.spacing.xs};
    width: ${({ theme }) => theme.spacing.xs};
  }
`
